'use strict';

angular.module('windmanagerApp')
  .directive('addTurbine', function() {
    return {
      templateUrl: 'components/directives/turbine/addTurbine/addTurbine.html',
      restrict: 'EA',
      controller: 'AddEditTurbineCtrl',
      controllerAs: 'aETurbine',
      scope: {
        site: '='
      },
      link: function(scope, element, attrs) {
      }
    };
  });
